import { GET_APP_VERSION } from '@/Api/graphql/queries/getVersion';
import version from '@/version';
import { useQuery } from '@apollo/client';
import { Paragraph } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { FooterVersionContainer } from './FooterVersion.styles';

const FooterVersion = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const { data: { getAppVersion } = { getAppVersion: null }, loading } = useQuery(GET_APP_VERSION);

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsPageLoaded(false);

    const timer = setTimeout(() => {
      setIsPageLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      {isPageLoaded && !loading && (
        <FooterVersionContainer>
          <Paragraph size="xsmall" weight="semibold">
            <span>
              {formatMessage({ id: 'version.version_app' })} {version}
            </span>
            {getAppVersion && (
              <span>
                &nbsp; - {formatMessage({ id: 'version.server' })} {getAppVersion}
              </span>
            )}
          </Paragraph>
          <Paragraph size="xsmall" weight="semibold">
            <span>&copy;&nbsp;{currentYear}.</span>
            <span>&nbsp;{formatMessage({ id: 'version.rights_reserved' })}</span>
          </Paragraph>
        </FooterVersionContainer>
      )}
    </>
  );
};

export default FooterVersion;
