import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from './i18n.d';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import ptBR from './languages/pt-BR';
import useMfeGetLocale from './useMfeGetLocale';

const DEFAULT_LANGUAGE = 'es-419';

type IIntlProvider = {
  children: ReactNode;
};

const messages: { [language: string]: MessageMap } = {
  'es-419': es419,
  'en-US': enUS,
  'pt-BR': ptBR,
};

export function getMessages(locale: string): { [key: string]: string } {
  return mergeMessages(messages, locale);
}

export function IntlProvider({ children }: Readonly<IIntlProvider>): JSX.Element {
  const locale = useMfeGetLocale();
  const mergedMessages = getMessages(locale);

  return (
    <NativeProvider locale={locale} defaultLocale={DEFAULT_LANGUAGE} messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
}

const flattenObject = (ob: MessageMap): { [key: string]: string } => {
  const toReturn: { [key: string]: string } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i] as MessageMap);
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i] as string;
    }
  }
  return toReturn;
};

const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string
): { [key: string]: string } => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE] as MessageMap);
  const localeMessages = flattenObject(messagesInput[selectedLocale] as MessageMap);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
